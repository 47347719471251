import * as React from 'react'
import axios from 'axios';

function Test(){
    const addEmailToActiveCampaign = async (email) => {
        fetch(`/.netlify/functions/addEmailToActiveCampaign?email=${email}`)
        .then(response => response.text())
        .then(data => console.log(data));
      };

    return(
        <div>
            <a onClick={() => addEmailToActiveCampaign("mr.marco.van.der.meer@gmail.com")}>click to test</a>
        </div>
    )
}

export default Test